.translation-labels {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.translation-labels > div {
  flex: 1 1 auto;
}

.section-card {
  margin: 1em 0 2em 0;
}

.section-card__content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

/* A card can have one or more of these items */
.grid__item {
  align-items: top;
  display: grid;
  grid-template-areas:
    'label-readonly label-readonly'
    'value-readonly value-readonly'
    'buttons        buttons'
    'value          value';
  grid-template-columns: 3fr 1fr;
  column-gap: 2em;
  row-gap: 1em;
  width: 100%;
}

.label--readonly,
.field--readonly {
  margin: 0;
  padding: 0;
}

.label--readonly {
  align-items: center;
  display: flex;
  grid-area: label-readonly;
  margin-bottom: 0 !important;
}

.field--readonly {
  align-items: center;
  background-color: #f4f4f4;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  word-break: break-all;
  display: flex;
  grid-area: value-readonly;
  padding: 0.32em 0.64em;
  white-space: pre-wrap;
}

.field--buttons {
  align-items: center;
  display: flex;
  gap: 0.64em;
  grid-area: buttons;
}

.field--buttons button {
  font-size: 1.64em;
}

/* You can change the selectors to match the angular material components, or nest the material components into a div and add the styles like that */
.field--editable {
  grid-area: value;
}

/* Quill takes too much space here */
.field--editable .ql-container {
  height: auto;
}

/* I'm not sure if they want to edit this form on their mobile, but if they do, then we need this. If they don't then let me know. */
@media screen and (min-width: 768px) {
  .grid__item {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      'label-readonly buttons'
      'value-readonly value';
  }

  .field--buttons {
    justify-content: space-between;
  }

  .field--readonly {
    padding: 0.08em 1em;
  }
}

.mat-form-field.fill-container,
.mat-form-field.fill-container .mat-form-field-infix,
.mat-form-field.fill-container .mat-form-field-flex,
.mat-form-field.fill-container .mat-form-field-wrapper,
.mat-form-field textarea {
  height: 100%;
}

.mat-form-field textarea {
  height: calc(100% - 25px);
}
